    /* Make a container full width on small screens and add padding on larger screens */
    .global {
        width: 100%;
        padding: 0rem;
    }
    @media (min-width: 640px) {
        .global {
        padding: 0rem;
        }
    }
    
    /* Center an element on small screens and align left on larger screens */
    /* .element {
        text-align: center;
    }
    @media (min-width: 640px) {
        .element {
        text-align: center;
        }
    } */
    
    /* Make an image full width on small screens and add max-width on larger screens */
    .pic {
        width: 100%;
        height: auto;
    }
    @media (min-width: 640px) {
        .img {
        max-width: 50%;
        }
    }